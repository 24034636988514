import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"

class AboutPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="About page" />
        <div className="col-lg-8 p1050">
          <article className="sppa">
            <header className="arhd">
              <h1 className="arhl">เกี่ยวกับเรา</h1>
            </header>

            <div className="tdpc">
              <p>คือการจัดเตรียมข้อมูลต่างๆให้กับผู้ค้าจากทั่วโลกด้วยข้อมูลที่มีคุณภาพ แม่นยำ และทันสมัย เกี่ยวกับโบรกเกอร์ฟอเร็กส์และแพลตฟอร์มทางเลือกไบนารี่ บอท กลยุทธ์ ข้อเสนอแนะ โปรโมชั่น และข่าวสารต่างๆ เรารู้ว่าในตลาดนั้นมีการหลอกลวงอยู่มาก จุดมุ่งหมายของเราคือการชี้ตัวบริษัทที่น่าสงสัยและเปิดโปงการฉ้อโกงของพวกเขาออกมาสู่สาธารณะชน</p>

              <p>เราอนุญาตให้ทุกคนสามารถแชร์ความคิดเห็นของพวกเขาที่มีต่อโบรกเกอร์หรือแพลตฟอร์มใดๆก็ตามที่มีอยู่ในรายชื่อ หากมันไม่ใช่การสแปมหรือความคิดเห็นที่หยาบคาย เราจะเผยแพร่ให้ทุกคนได้เห็น แม้ว่ามันอาจเป็นคำวิจารณ์ เราจะหลีกเลี่ยงการลบความคิดเห็นเหล่านั้น แต่จะเชิญชวนให้โบรกเกอร์ที่ถูกวิจารณ์เหล่านั้นมาแก้ปัญหาให้กับผู้ค้าขายด้วยตนเอง เราจะเตรียมพื้นที่สนทนาระหว่างผู้ซื้อขายและแพลตฟอร์ม เพื่อที่จะเป็นประโยชน์ให้กับทั้งฝ่ายผู้ซื่อขายและแพลตฟอร์มการซื้อขาย</p>
            </div>
          </article>

        </div> 
        
      </Layout>
    )
  }
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
